import * as React from "react";

// Load fonts
import "./src/fonts/fonts.css";

// import { CartContextProvider } from "./src/contexts/CartContext"
import { StoreProvider } from "./src/contexts/cart";

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);
